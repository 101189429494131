<template>
  <div>
    <Toast />

    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '450px' }"
      header="New Form"
      :modal="true"
      class="p-fluid"
    >
      <img
        src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
        :alt="product.image"
        class="product-image"
        v-if="product.image"
      />
      <div class="field">
        <label for="name">Nome da empresa</label>
        <InputText
          id="name"
          v-model.trim="newCompany"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.name }"
        />
        <small class="p-error" v-if="submitted && !product.name"
          >Nome da empresa é obrigatório.</small
        >
      </div>
      <div class="field">
        <label for="name">Responsável</label>
        <InputText
          id="name"
          v-model.trim="newResponsible"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !product.user }"
        />
        <small class="p-error" v-if="submitted && !product.user"
          >Responsável é obrigatório.</small
        >
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Salvar"
          icon="pi pi-check"
          class="p-button-text"
          @click="newTPMForm"
        />
      </template>
    </Dialog>
    <Toolbar class="mb-4">
      <template #start>
        <Button
          label="Novo"
          icon="pi pi-plus"
          class="p-button-success mr-2"
          @click="openNew"
        />
      </template>
    </Toolbar>
    <div class="card">
      <div v-if="products.length > 0">
        <DataTable
          :value="products"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          responsiveLayout="scroll"
        >
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="company" header="Company" sortable></Column>
          <Column field="responsible" header="responsible" sortable></Column>
          <Column
            field="final_score_operational_risk"
            header="final_score_operational_risk"
            sortable
          ></Column>
          <Column
            field="final_score_security"
            header="final_score_security"
            sortable
          ></Column>
          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5>Notas para {{ slotProps.data.company }}</h5>
              <DataTable
                :value="slotProps.data.expandData"
                responsiveLayout="scroll"
              >
                <Column
                  field="created_date"
                  header="Data de criação"
                  sortable
                ></Column>
                <Column
                  field="response_date"
                  header="Data de resposta"
                  sortable
                ></Column>
                <Column
                  field="operational_impact_1_hour"
                  header="operational_impact_1_hour"
                  sortable
                ></Column>
                <Column
                  field="operational_impact_6_hour"
                  header="operational_impact_6_hour"
                  sortable
                ></Column>
                <Column
                  field="operational_impact_12_hour"
                  header="operational_impact_12_hour"
                  sortable
                ></Column>
                <Column
                  field="reputational_impact_1_hour"
                  header="reputational_impact_1_hour"
                  sortable
                ></Column>
                <Column
                  field="reputational_impact_6_hour"
                  header="reputational_impact_6_hour"
                  sortable
                ></Column>
                <Column
                  field="reputational_impact_12_hour"
                  header="reputational_impact_12_hour"
                  sortable
                ></Column>
                <Column
                  field="regulatory_impact_1_hour"
                  header="regulatory_impact_1_hour"
                  sortable
                ></Column>
                <Column
                  field="regulatory_impact_6_hour"
                  header="regulatory_impact_6_hour"
                  sortable
                ></Column>
                <Column
                  field="regulatory_impact_12_hour"
                  header="regulatory_impact_12_hour"
                  sortable
                ></Column>
                <Column
                  field="financial_impact_1_hour"
                  header="financial_impact_1_hour"
                  sortable
                ></Column>
                <Column
                  field="financial_impact_6_hour"
                  header="financial_impact_6_hour"
                  sortable
                ></Column>
                <Column
                  field="financial_impact_12_hour"
                  header="financial_impact_12_hour"
                  sortable
                ></Column>
                <Column
                  field="loss_of_confidentiality_impact"
                  header="loss_of_confidentiality_impact"
                  sortable
                ></Column>
                <Column
                  field="loss_of_confidentiality_probability"
                  header="loss_of_confidentiality_probability"
                  sortable
                ></Column>
                <Column
                  field="loss_of_disponibility_impact"
                  header="loss_of_disponibility_impact"
                  sortable
                ></Column>
                <Column
                  field="loss_of_disponibility_probability"
                  header="loss_of_disponibility_probability"
                  sortable
                ></Column>
                <Column
                  field="loss_of_integrity_impact"
                  header="loss_of_integrity_impact"
                  sortable
                ></Column>
                <Column
                  field="loss_of_integrity_probability"
                  header="loss_of_integrity_probability"
                  sortable
                ></Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import TPMService from "../service/TPMService";

export default {
  data() {
    return {
      products: [],
      expandedRows: [],
      product: {},
      submitted: false,
      productDialog: false,
      newCompany: "",
      newResponsible: "",
    };
  },

  created() {
    TPMService.getForms().then((data) => {
      if (data == null) {
        this.products = [];
        return;
      }
      this.products = data;
      let opriskMap = {
        0: "Baixo",
        1: "Moderado",
        2: "Alto",
      };
      let soImpactMap = {
        0: "Baixo",
        1: "Médio",
        2: "Alto",
      };
      let soProbMap = {
        0: "Desconhecido",
        1: "Baixo",
        2: "Médio",
        3: "Alto",
      };
      this.products.forEach((p) => {
        let created_date = new Date(p.created_date);
        let response_date = new Date(p.response_date);
        let created_date_text =
          "" +
          created_date.getDay() +
          "/" +
          created_date.getUTCMonth() +
          "/" +
          created_date.getFullYear();
        let response_date_text =
          response_date.getDay() +
          "/" +
          response_date.getUTCMonth() +
          "/" +
          response_date.getFullYear();
        p["expandData"] = [
          {
            created_date: created_date_text,
            response_date: response_date_text,
            operational_impact_1_hour: opriskMap[p.operational_impact_1_hour],
            operational_impact_6_hour: opriskMap[p.operational_impact_6_hour],
            operational_impact_12_hour: opriskMap[p.operational_impact_12_hour],
            reputational_impact_1_hour: opriskMap[p.reputational_impact_1_hour],
            reputational_impact_6_hour: opriskMap[p.reputational_impact_6_hour],
            reputational_impact_12_hour:
              opriskMap[p.reputational_impact_12_hour],
            regulatory_impact_1_hour: opriskMap[p.regulatory_impact_1_hour],
            regulatory_impact_6_hour: opriskMap[p.regulatory_impact_6_hour],
            regulatory_impact_12_hour: opriskMap[p.regulatory_impact_12_hour],
            financial_impact_1_hour: opriskMap[p.financial_impact_1_hour],
            financial_impact_6_hour: opriskMap[p.financial_impact_6_hour],
            financial_impact_12_hour: opriskMap[p.financial_impact_12_hour],
            loss_of_confidentiality_impact:
              soImpactMap[p.loss_of_confidentiality_impact],
            loss_of_confidentiality_probability:
              soProbMap[p.loss_of_confidentiality_probability],
            loss_of_disponibility_impact:
              soImpactMap[p.loss_of_disponibility_impact],
            loss_of_disponibility_probability:
              soProbMap[p.loss_of_disponibility_probability],
            loss_of_integrity_impact: soImpactMap[p.loss_of_integrity_impact],
            loss_of_integrity_probability:
              soProbMap[p.loss_of_integrity_probability],
          },
        ];
      });
      console.log(this.products.length);
    });
  },
  methods: {
    onRowExpand() {},
    onRowCollapse() {},
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    newTPMForm() {
      TPMService.newForm(this.newResponsible, this.newCompany).then(
        () => this.hideDialog
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
  padding: 1rem;
}
</style>                    